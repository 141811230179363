var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('div',{staticClass:"mr-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"mr-1",attrs:{"variant":"primary"}},[_vm._v(" Добавить город ")])],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Поиск","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],on:{"click":function($event){return _vm.getCity(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Редактировать")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Удалить")])],1)],1)],1)]):_vm._e()]}}])}),_c('infinite-loading',{attrs:{"spinner":"bubbles"},on:{"infinite":_vm.infiniteScroll}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],1)],_c('b-modal',{ref:"cities-modal",attrs:{"id":"modal-1","title":"Город","ok-only":"","ok-title":"Accept","hide-footer":""}},[_c('b-form-group',[_c('b-form-input',{staticClass:"inline-block",attrs:{"placeholder":"Название города"},model:{value:(_vm.city.name),callback:function ($$v) {_vm.$set(_vm.city, "name", $$v)},expression:"city.name"}})],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.updateItem}},[_vm._v(" Сохранить ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }