<template>
  <div>
    <template>
      <div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <div class="mr-auto">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-1
              variant="primary"
              class="mr-1"
            >
              Добавить город
            </b-button>
          </div>
          <b-form-group>
            <div class="d-flex align-items-center">

              <b-form-input
                v-model="searchTerm"
                placeholder="Поиск"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'action'">

              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.modal-1
                    @click="getCity(props.row.id)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Редактировать</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteItem(props.row.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>
        </vue-good-table>
        <infinite-loading
          spinner="bubbles"
          @infinite="infiniteScroll"
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </template>

    <b-modal
      id="modal-1"
      ref="cities-modal"
      title="Город"
      ok-only
      ok-title="Accept"
      hide-footer
    >
      <b-form-group>
        <b-form-input
          v-model="city.name"
          placeholder="Название города"
          class="inline-block"
        />
      </b-form-group>
      <b-button
        variant="primary"
        class="mr-1"
        @click="updateItem"
      >
        Сохранить
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BModal, BButton, VBModal, BFormInput, BFormGroup, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import InfiniteLoading from 'vue-infinite-loading'
import crud_module from '@/libs/crud_module'

const cities_module = crud_module({
  entity_name: 'city',
})

export default {
  name: 'EditCities',
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    InfiniteLoading,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      skipCount: 20,
      maxResultCount: 20,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Город',
          field: 'name',
        },
        {
          label: 'Действие',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      city: {
        id: '',
        name: '',
      },
    }
  },
  async created() {
    try {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      const response = await cities_module.fetchItems(payload)
      this.rows = response.data.rows
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async infiniteScroll($state) {
      const payload = {
        skipCount: this.skipCount,
        maxResultCount: this.maxResultCount,
      }
      await cities_module.fetchItems(payload)
        .then(res => {
          if (res.data.rows.length > 0) {
            this.rows.push(...res.data.rows)
            this.skipCount += this.maxResultCount
            $state.loaded()
          } else {
            $state.complete()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async deleteItem(id) {
      const payload = {
        skipCount: '',
        maxResultCount: this.maxResultCount,
      }
      await cities_module.deleteItem(id)
      await cities_module.fetchItems(payload).then(response => {
        this.rows = response.data.rows
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          icon: 'BellIcon',
          text: 'Город удален',
          variant: 'success',
        },
      })
    },

    updateItem() {
      if (this.city.id) {
        this.saveItem()
      } else {
        this.createItem()
      }
    },
    async saveItem() {
      const result = await cities_module.updateItem(this.city.id, {
        name: this.city.name,
      })
      if (result.status === 200) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Город сохранен',
            variant: 'success',
          },
        })
        this.$refs['cities-modal'].hide()
        this.city = {
          name: '',
        }
        const payload = {
          skipCount: '',
          maxResultCount: this.maxResultCount,
        }
        await cities_module.fetchItems(payload).then(response => {
          this.rows = response.data.rows
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
    async createItem() {
      const result = await cities_module.createItem({
        name: this.city.name,
      })

      if (result.status === 201) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Город сохранен',
            variant: 'success',
          },
        })
        this.$refs['cities-modal'].hide()
        this.city = {
          name: '',
        }
        const payload = {
          skipCount: '',
          maxResultCount: this.maxResultCount,
        }
        await cities_module.fetchItems(payload).then(response => {
          this.rows = response.data.rows
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
    async getCity(id) {
      const city = await cities_module.fetchItem(id).then(r => r.data)
      this.city = city
    },
  },
}

</script>

<style  lang="scss">

svg{
  transition: .3s;
  &:hover{
    transform: scale(1.2);
    cursor: pointer;
  }
}
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
